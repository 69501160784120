import axios from 'axios';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';
// import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
// const BASE_URL =`https://event-care-api.invoacdmy.com`
// const BASE_URL =`https://event-care-api.ai2digital.com`
// const BASE_URL =`http://localhost:5555`
// const BASE_URL =`https://on-fast.wik.ydh.mybluehost.me`
// const BASE_URL =`https://on-fast-api.afyamed.co`
// const BASE_URL =`https://on-fast-api.ai2digital.com`
const BASE_URL =`https://api.safriapp.com`
// const BASE_URL =`${process.env.REACT_APP_API_KEY}`
const axiosConfig = axios.create({
    baseURL: BASE_URL,
    headers: {
        // "accept-language": `en`
        "accept-language": Cookies.get('i18next') ||'en'
    }
});
// const axiosConfigNoRegister = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//         'Content-Type': 'application/json',
//     }
// });
axiosConfig.interceptors.response.use(response => {
    return response;
}, async(error) => {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
        toast.error('Account Opened From Other Device. Please Login Again')
    }
    return Promise.reject(error)
 });
export {axiosConfig ,BASE_URL};